<template>
    <baseContainer>
        <template v-slot:heads>
            <HeadNav/>
        </template>
        <div class="switchIdentities">
            <van-radio-group v-model="radio">
                <van-cell-group>
                    <van-cell :title="identityName[item]"
                              v-for="item in userVo.roleList"
                              :key="item"
                              clickable>
                        <template #right-icon>
                            <van-radio :name="item"/>
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-radio-group>
        </div>
        <template v-slot:foots>
            <Foots></Foots>
        </template>
    </baseContainer>
</template>

<script>
import {mapState} from 'vuex';
import HeadNav from '../../components/headNav';
import Foots from '../../components/foots';
import constant from '../../utils/constant';

export default {
    name: "switchIdentities",
    components: {
        HeadNav,
        Foots
    },
    computed: {
        ...mapState({
            identity: state => state.users.identity,
            userVo: state => state.users.userVo
        }),
        radio: {
            get () {
                return this.identity;
            },
            set (val) {
                this.$store.dispatch('users/changeIdentity', val);
            }
        }
    },
    data () {
        return {
            identityName: constant.identityName
        }
    }
}
</script>

<style scoped lang="less">
    .switchIdentities {
        padding: 60px 40px;
    }

    .van-cell {
        height: 176px;
        background: #1A78F9;
        border-radius: 8px;
        margin-bottom: 20px;
    }

    .van-cell__title {
        line-height: 128px;
        font-size: 34px;
        font-weight: 500;
        color: #FFFFFF;
    }

    .van-cell:after {
        border-bottom: 0 solid #ebedf0;
    }

    /deep/ .van-radio__icon--checked i {
        color: #fff;
        background-color: #1A78F9;
        border-color: #fff;
    }
</style>