<template>
    <div class="foots">
        <van-tabbar route>
            <van-tabbar-item to="/home" icon="wap-home">首页</van-tabbar-item>
            <van-tabbar-item v-if="culength"
                             to="/personalSpace" icon="manager">我的
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: "foots",
    computed: {
        ...mapState({
            userVo: state => state.users.userVo
        }),
        culength () {
            return Object.keys(this.userVo).length;
        }
    },
    data () {
        return {};
    },
    methods: {},
    created () {
    },
    mounted () {
    },
    components: {},
    watch: {}
};
</script>

<style scoped lang="less">
    .foots {
        flex: 0 0 96px;
    }
</style>
