import { render, staticRenderFns } from "./switchIdentities.vue?vue&type=template&id=219e6303&scoped=true&"
import script from "./switchIdentities.vue?vue&type=script&lang=js&"
export * from "./switchIdentities.vue?vue&type=script&lang=js&"
import style0 from "./switchIdentities.vue?vue&type=style&index=0&id=219e6303&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "219e6303",
  null
  
)

export default component.exports